<template>
  <el-dialog
		title="Ангилал нэмэх"
		:visible.sync="dialogVisible"
		width="50%"
    :custom-class="dialogCustomClass"
		:before-close="closeCategoryDialog">
    <el-form  :rules="rules" ref="variantForm">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Ангилал сонгох" prop="menu_category_mon">
           <el-select
            v-model="categoryForm"
            value-key="menu_category_mon"
            filterable=""
            remote=""
            reserve-keyword
            placeholder="хоол сонгох"
          >
            <el-option
              v-for="(item, index) in menuCategoryList"
              :key="index"
              :label="item.menu_category_mon"
              :value="item"
            >
            </el-option>
           </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
			<el-button @click="closeCategoryDialog">Болих</el-button>
			<el-button type="success" @click="addCategory(categoryForm)">Нэмэх</el-button>
		</span>
  </el-dialog>
</template>
<script>
export default {
  name: 'AddCategoryDialog',
  props: {
    dialogVisible: Boolean,
    closeCategoryDialog: Function,
    addCategory: Function,
    menuCategoryList: Array
  },
  data () {
    return {
      dialogCustomClass: 'custom-dialog-class',
      categoryForm: {
        menu_category_mon: '',
        menu_category_eng: ''
      },
      rules: {
        key: [
          { required: true, message: 'Нэрийг оруулна уу', trigger: 'blur' }
        ],
        key_eng: [
          { required: true, message: 'Англи Нэрийг оруулна уу', trigger: 'blur' }
        ]
      }
    }
  }
}
</script>
<style>
.custom-dialog-class {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
</style>
