<template>
  <div class="h-full w-full md:flex md:flex-col relative md:pb-0 pb-16">
    <div class="flex flex-col mx-5 my-5 md:hidden gap-4">
      <div class="flex flex-row gap-2.5 items-center">
        <div class="h-30 bg-baseOrange w-1 rounded"></div>
        <span class=" text-2xl font-bold">Бүтээгдэхүүн</span>
      </div>
      <el-select class="element-select-category text-sm cursor-pointer" value-key="_id" v-model="currentOutlet" @change="setOutlet">
        <el-option
          v-for="(outlet,
          outletIndex) in $root.chosenOutlets"
          :key="outletIndex"
          :label="outlet.full_name_mon"
          :value="outlet">
          {{ outlet.full_name_mon }}
        </el-option>
      </el-select>
    </div>
    <div class="bg-baseOrange text-9 font-semibold h-9 px-2.5 md:flex flex-row items-center gap-2.5 cursor-pointer hidden" v-if="selectedProducts.length > 0">
      <div class="bg-baseBlack text-baseWhite rounded-full w-14 h-14 flex flex-row justify-center items-center text-9">
        {{ selectedProducts.length }}
      </div>
      <div class="flex flex-row justify-between w-full" @click="copyProductDialog = true">
        <span>Бүтээгдэхүүн хуулах/устгах</span>
        <svg width="16" height="16" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1807_23577)">
          <path d="M10.6875 15.5417H4.35417C3.19976 15.5404 2.09299 15.0813 1.27669 14.265C0.460402 13.4487 0.0012573 12.3419 0 11.1875L0 4.85417C0.0012573 3.69976 0.460402 2.59299 1.27669 1.77669C2.09299 0.960402 3.19976 0.501257 4.35417 0.5L10.6875 0.5C11.8419 0.501257 12.9487 0.960402 13.765 1.77669C14.5813 2.59299 15.0404 3.69976 15.0417 4.85417V11.1875C15.0404 12.3419 14.5813 13.4487 13.765 14.265C12.9487 15.0813 11.8419 15.5404 10.6875 15.5417ZM4.35417 2.875C3.82926 2.875 3.32585 3.08352 2.95468 3.45468C2.58352 3.82585 2.375 4.32926 2.375 4.85417V11.1875C2.375 11.7124 2.58352 12.2158 2.95468 12.587C3.32585 12.9581 3.82926 13.1667 4.35417 13.1667H10.6875C11.2124 13.1667 11.7158 12.9581 12.087 12.587C12.4581 12.2158 12.6667 11.7124 12.6667 11.1875V4.85417C12.6667 4.32926 12.4581 3.82585 12.087 3.45468C11.7158 3.08352 11.2124 2.875 10.6875 2.875H4.35417ZM19 15.1458V6.04167C19 5.72672 18.8749 5.42468 18.6522 5.20198C18.4295 4.97928 18.1274 4.85417 17.8125 4.85417C17.4976 4.85417 17.1955 4.97928 16.9728 5.20198C16.7501 5.42468 16.625 5.72672 16.625 6.04167V15.1458C16.625 15.6707 16.4165 16.1742 16.0453 16.5453C15.6742 16.9165 15.1707 17.125 14.6458 17.125H5.54167C5.22672 17.125 4.92468 17.2501 4.70198 17.4728C4.47928 17.6955 4.35417 17.9976 4.35417 18.3125C4.35417 18.6274 4.47928 18.9295 4.70198 19.1522C4.92468 19.3749 5.22672 19.5 5.54167 19.5H14.6458C15.8002 19.4987 16.907 19.0396 17.7233 18.2233C18.5396 17.407 18.9987 16.3002 19 15.1458Z" fill="#231F20"/>
          </g>
          <defs>
          <clipPath id="clip0_1807_23577">
          <rect width="19" height="19" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <el-input ref="searchInput" size="medium" class="searchInputClass w-full border-baseGray text-11 border-t-0" placeholder="Бүтээгдэхүүн хайх" v-model="searchText" @input="filterProduct(searchText)">
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <ul class="border-r border-r-[1px] border-solid md:w-216 text-11 font-normal border-baseGray md:h-full" v-loading="menuLoading">
      <li class="flex flex-col border-b border-solid border-baseGray cursor-pointer"
        v-for="(category, indexCategory) in partnerCategories"
        :key="indexCategory">
        <div class="flex flex-row h-43 max-h-full justify-between items-center px-15" style="min-height: 43px; max-height: 100%" @click="setSelectCategory(category)" :class="category?.menu_category_mon === selectCategory?.menu_category_mon ? 'Acitve' : ''">
          <span :class="category?.menu_category_mon === selectCategory?.menu_category_mon ? ' font-semibold' : ''">{{ category.menu_category_mon }}</span>
          <div class="flex flex-row gap-5">
            <div class="flex flex-row justify-center items-center h-18 w-18 bg-light-gray rounded-full">{{
              products.filter(
                productItem =>
                  productItem.menu_categories &&
                  productItem.menu_categories.menu_category_eng ===
                    category.menu_category_eng
              ).length}}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" v-if="category?.is_select">
              <path d="M14.8124 12.5004C14.7388 12.5746 14.6512 12.6335 14.5548 12.6737C14.4583 12.7139 14.3548 12.7346 14.2503 12.7346C14.1458 12.7346 14.0423 12.7139 13.9458 12.6737C13.8494 12.6335 13.7618 12.5746 13.6882 12.5004L10.0624 8.87462C9.98879 8.80042 9.90123 8.74152 9.80476 8.70133C9.70829 8.66114 9.60481 8.64045 9.5003 8.64045C9.39579 8.64045 9.29232 8.66114 9.19585 8.70133C9.09937 8.74152 9.01181 8.80042 8.93822 8.87462L5.31239 12.5004C5.23879 12.5746 5.15123 12.6335 5.05476 12.6737C4.95829 12.7139 4.85481 12.7346 4.7503 12.7346C4.64579 12.7346 4.54232 12.7139 4.44585 12.6737C4.34937 12.6335 4.26181 12.5746 4.18822 12.5004C4.04077 12.3521 3.95801 12.1515 3.95801 11.9423C3.95801 11.7332 4.04077 11.5325 4.18822 11.3842L7.82197 7.75045C8.26728 7.3057 8.87092 7.05588 9.5003 7.05588C10.1297 7.05588 10.7333 7.3057 11.1786 7.75045L14.8124 11.3842C14.9598 11.5325 15.0426 11.7332 15.0426 11.9423C15.0426 12.1515 14.9598 12.3521 14.8124 12.5004Z" fill="#3A3A3C"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" v-else>
              <path d="M14.8124 6.49958C14.7388 6.42538 14.6512 6.36649 14.5548 6.3263C14.4583 6.2861 14.3548 6.26541 14.2503 6.26541C14.1458 6.26541 14.0423 6.2861 13.9458 6.3263C13.8494 6.36649 13.7618 6.42538 13.6882 6.49958L10.0624 10.1254C9.98879 10.1996 9.90123 10.2585 9.80476 10.2987C9.70829 10.3389 9.60481 10.3596 9.5003 10.3596C9.39579 10.3596 9.29232 10.3389 9.19585 10.2987C9.09937 10.2585 9.01181 10.1996 8.93822 10.1254L5.31239 6.49958C5.23879 6.42538 5.15123 6.36649 5.05476 6.3263C4.95829 6.2861 4.85481 6.26541 4.7503 6.26541C4.64579 6.26541 4.54232 6.2861 4.44585 6.3263C4.34937 6.36649 4.26181 6.42538 4.18822 6.49958C4.04077 6.64791 3.95801 6.84856 3.95801 7.05771C3.95801 7.26686 4.04077 7.4675 4.18822 7.61583L7.82197 11.2496C8.26728 11.6943 8.87092 11.9442 9.5003 11.9442C10.1297 11.9442 10.7333 11.6943 11.1786 11.2496L14.8124 7.61583C14.9598 7.4675 15.0426 7.26686 15.0426 7.05771C15.0426 6.84856 14.9598 6.64791 14.8124 6.49958Z" fill="#3A3A3C"/>
            </svg>
          </div>
        </div>
        <!-- Product section -->
        <ul class="w-full" v-if="category?.is_select">
          <li class="flex flex-row justify-between items-center border-baseGray border-t-1 px-15 bg-baseBackgroundGray" style="min-height: 33px; max-height: 100%"
            v-for="(product, productIndex) in products.filter(
              productItem =>
                productItem.menu_categories &&
                productItem.menu_categories.menu_category_eng ===
                  category?.menu_category_eng
            )"
            :class="menu && product.pkey === menu.pkey ? 'productActiveClass' : ''"
            :key="productIndex">
            <el-checkbox class="w-18 h-18 custom-checkbox" @change="setProduct(product)" v-model="product.is_checked"></el-checkbox>
            <div class="flex flex-row gap-5 items-center  h-full w-full ml-1"  @click="getMenu(product._id)" :class="menu && product.pkey === menu.pkey ? 'bg-baseGray' : 'bg-baseBackgroundGray'">
              <span :class="menu && product.pkey === menu.pkey ? 'font-semibold' : ''" class="w-full">{{ product.name_mon }}</span>
            </div>
            <el-switch style="width: 30px;" @change="activeProduct(product)" v-model="product.is_active"></el-switch>
          </li>
          <li class="flex flex-row justify-center items-center px-15 gap-5 h-33 text-11 text-success" style="min-height: 33px" @click="addMenu(category)">
            <i class="el-icon-plus"></i> Нэмэх
          </li>
        </ul>
      </li>
      <!-- Zoruutei buteegdehuun -->
      <li class="flex flex-col border-b border-solid border-baseGray cursor-pointer">
        <div class="flex flex-row h-43 max-h-full justify-between items-center px-15" @click="zoruuSelect = !zoruuSelect">
          <span class="text-baseTextGray">Ангилал зөрүүтэй</span>
          <div class="flex flex-row gap-5 text-baseTextGray">
            <div class="flex flex-row justify-center items-center h-18 w-18 bg-light-gray rounded-full">{{
              products.filter(productItem =>
                !partnerCategories.find(
                  el =>
                    el.menu_category_eng ===
                    productItem.menu_categories.menu_category_eng
                )
                  ? productItem
                  : null
              ).length}}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" v-if="zoruuSelect">
              <path d="M14.8124 12.5004C14.7388 12.5746 14.6512 12.6335 14.5548 12.6737C14.4583 12.7139 14.3548 12.7346 14.2503 12.7346C14.1458 12.7346 14.0423 12.7139 13.9458 12.6737C13.8494 12.6335 13.7618 12.5746 13.6882 12.5004L10.0624 8.87462C9.98879 8.80042 9.90123 8.74152 9.80476 8.70133C9.70829 8.66114 9.60481 8.64045 9.5003 8.64045C9.39579 8.64045 9.29232 8.66114 9.19585 8.70133C9.09937 8.74152 9.01181 8.80042 8.93822 8.87462L5.31239 12.5004C5.23879 12.5746 5.15123 12.6335 5.05476 12.6737C4.95829 12.7139 4.85481 12.7346 4.7503 12.7346C4.64579 12.7346 4.54232 12.7139 4.44585 12.6737C4.34937 12.6335 4.26181 12.5746 4.18822 12.5004C4.04077 12.3521 3.95801 12.1515 3.95801 11.9423C3.95801 11.7332 4.04077 11.5325 4.18822 11.3842L7.82197 7.75045C8.26728 7.3057 8.87092 7.05588 9.5003 7.05588C10.1297 7.05588 10.7333 7.3057 11.1786 7.75045L14.8124 11.3842C14.9598 11.5325 15.0426 11.7332 15.0426 11.9423C15.0426 12.1515 14.9598 12.3521 14.8124 12.5004Z" fill="#3A3A3C"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" v-else>
              <path d="M14.8124 6.49958C14.7388 6.42538 14.6512 6.36649 14.5548 6.3263C14.4583 6.2861 14.3548 6.26541 14.2503 6.26541C14.1458 6.26541 14.0423 6.2861 13.9458 6.3263C13.8494 6.36649 13.7618 6.42538 13.6882 6.49958L10.0624 10.1254C9.98879 10.1996 9.90123 10.2585 9.80476 10.2987C9.70829 10.3389 9.60481 10.3596 9.5003 10.3596C9.39579 10.3596 9.29232 10.3389 9.19585 10.2987C9.09937 10.2585 9.01181 10.1996 8.93822 10.1254L5.31239 6.49958C5.23879 6.42538 5.15123 6.36649 5.05476 6.3263C4.95829 6.2861 4.85481 6.26541 4.7503 6.26541C4.64579 6.26541 4.54232 6.2861 4.44585 6.3263C4.34937 6.36649 4.26181 6.42538 4.18822 6.49958C4.04077 6.64791 3.95801 6.84856 3.95801 7.05771C3.95801 7.26686 4.04077 7.4675 4.18822 7.61583L7.82197 11.2496C8.26728 11.6943 8.87092 11.9442 9.5003 11.9442C10.1297 11.9442 10.7333 11.6943 11.1786 11.2496L14.8124 7.61583C14.9598 7.4675 15.0426 7.26686 15.0426 7.05771C15.0426 6.84856 14.9598 6.64791 14.8124 6.49958Z" fill="#3A3A3C"/>
            </svg>
          </div>
        </div>
        <ul class="w-full" v-if="zoruuSelect">
          <li class="flex flex-row justify-between items-center border-baseGray border-t-1 px-15 h-33 bg-baseBackgroundGray" style="min-height: 33px; max-height: 100%"
            v-for="(product, product1Index) in products.filter(productItem => {
                    return !partnerCategories.find(
                      el =>
                        el.menu_category_eng ===
                        productItem.menu_categories.menu_category_eng
                    )
                      ? productItem
                      : null;
                  })"
            :class="menu && product.pkey === menu.pkey ? 'productActiveClass' : ''"
            :key="product1Index">
            <el-checkbox class="w-18 h-18 custom-checkbox" @change="setProduct(product)" v-model="product.is_checked"></el-checkbox>
            <div class="flex flex-row gap-5 items-center  h-full w-full ml-1"  @click="getMenu(product._id)" :class="menu && product.pkey === menu.pkey ? 'bg-baseGray' : 'bg-baseBackgroundGray'">
              <span :class="menu && product.pkey === menu.pkey ? 'font-semibold' : ''" class="w-full">{{ product.name_mon }}</span>
            </div>
            <el-switch style="width: 30px;" @change="activeProduct(product)" v-model="product.is_active"></el-switch>
          </li>
        </ul>
      </li>
      <!-- Add Category -->
      <li class="flex flex-row gap-5 px-15 border-b border-solid border-baseGray cursor-pointer justify-center items-center h-33 text-11" @click="addMenuCatgory">
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.5 13.5H5.5V11.5H11.5V5.5H13.5V11.5H19.5V13.5H13.5V19.5H11.5V13.5Z" fill="#231F20"/>
        </svg>
        Ангилал нэмэх
      </li>
    </ul>
    <!-- Бүтээгдэхүүн хуулах/устгах Mobile button -->
    <div class="w-full flex flex-row justify-center items-center md:hidden bottom-4 fixed z-10 px-4" v-if="selectedProducts.length > 0">
      <div class="bg-baseOrange font-semibold p-4 flex flex-row justify-center items-center gap-2.5 cursor-pointer w-full rounded-xl text-base">
        <div class="bg-baseBlack text-baseWhite rounded-full w-14 h-14 flex flex-row justify-center items-center text-xs">
          {{ selectedProducts.length }}
        </div>
        <div class="flex flex-row justify-between w-full" @click="copyProductDialog = true">
          <span>Бүтээгдэхүүн хуулах/устгах</span>
          <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1807_23577)">
            <path d="M10.6875 15.5417H4.35417C3.19976 15.5404 2.09299 15.0813 1.27669 14.265C0.460402 13.4487 0.0012573 12.3419 0 11.1875L0 4.85417C0.0012573 3.69976 0.460402 2.59299 1.27669 1.77669C2.09299 0.960402 3.19976 0.501257 4.35417 0.5L10.6875 0.5C11.8419 0.501257 12.9487 0.960402 13.765 1.77669C14.5813 2.59299 15.0404 3.69976 15.0417 4.85417V11.1875C15.0404 12.3419 14.5813 13.4487 13.765 14.265C12.9487 15.0813 11.8419 15.5404 10.6875 15.5417ZM4.35417 2.875C3.82926 2.875 3.32585 3.08352 2.95468 3.45468C2.58352 3.82585 2.375 4.32926 2.375 4.85417V11.1875C2.375 11.7124 2.58352 12.2158 2.95468 12.587C3.32585 12.9581 3.82926 13.1667 4.35417 13.1667H10.6875C11.2124 13.1667 11.7158 12.9581 12.087 12.587C12.4581 12.2158 12.6667 11.7124 12.6667 11.1875V4.85417C12.6667 4.32926 12.4581 3.82585 12.087 3.45468C11.7158 3.08352 11.2124 2.875 10.6875 2.875H4.35417ZM19 15.1458V6.04167C19 5.72672 18.8749 5.42468 18.6522 5.20198C18.4295 4.97928 18.1274 4.85417 17.8125 4.85417C17.4976 4.85417 17.1955 4.97928 16.9728 5.20198C16.7501 5.42468 16.625 5.72672 16.625 6.04167V15.1458C16.625 15.6707 16.4165 16.1742 16.0453 16.5453C15.6742 16.9165 15.1707 17.125 14.6458 17.125H5.54167C5.22672 17.125 4.92468 17.2501 4.70198 17.4728C4.47928 17.6955 4.35417 17.9976 4.35417 18.3125C4.35417 18.6274 4.47928 18.9295 4.70198 19.1522C4.92468 19.3749 5.22672 19.5 5.54167 19.5H14.6458C15.8002 19.4987 16.907 19.0396 17.7233 18.2233C18.5396 17.407 18.9987 16.3002 19 15.1458Z" fill="#231F20"/>
            </g>
            <defs>
            <clipPath id="clip0_1807_23577">
            <rect width="19" height="19" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <el-dialog
      :custom-class="dialogCustomClass"
      title="Бүтээгдэхүүн хуулах/устгах"
      :visible.sync="copyProductDialog"
      width="40%"
    >
      <div class="flex flex-row justify-center items-center text-center gap-5 text-baseWhite bg-baseRed rounded-lg break-normal">
        <i class="el-icon-warning-outline"></i> ДАВХАРДСАН БҮТЭЭГДЭХҮҮН
          БАЙНА УУ? ТА САЙН ШАЛГАЖ ХУУЛНА УУ?
      </div>
      <el-form :model="selectSuppliers" ref="selectSuppliers" :rules="rules">
        <el-form-item prop="suppliers">
          <el-select
            v-model="selectSuppliers.suppliers"
            multiple
            clearable
            placeholder="Хуулах салбараа сонгоно уу?"
            class="block mt10"
          >
            <el-option
              v-for="(supplier, supplierIndex) in $root.chosenOutlets"
              :key="supplierIndex"
              :label="supplier.full_name_eng"
              :value="supplier._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table :data="selectedProducts">
        <el-table-column label="Зураг">
          <template slot-scope="scope">
            <el-image
              :src="scope.row.img_url"
              style="width: 80px; height: 50px"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column label="Салбар">
          <template slot-scope="scope">
            <span>{{ scope.row.outlet_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Бүтээгдэхүүн" prop="name_mon"></el-table-column>
        <el-table-column width="50px">
          <template slot-scope="scope">
            <el-checkbox
              @change="setProduct(scope.row)"
              v-model="scope.row.is_checked"
            ></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeCopyProductDialog"
          >Буцах</el-button
        >
        <el-popconfirm
          title="Та устгахдаа итгэлтэй байна уу?"
          confirm-button-text="Тийм"
          cancel-button-text="Үгүй"
          @confirm="deleteProducts"
        >
          <el-button
            type="danger"
            size="small"
            slot="reference"
            class="mx-2.5"
            :loading="copyLoading"
            >Устгах</el-button
          >
        </el-popconfirm>
        <el-button
          type="success"
          size="small"
          @click="copyProduct('selectSuppliers')"
          :loading="copyLoading"
          >Хуулах</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mAxios from '../../../../helper/axiosInstance'
export default {
  name: 'CategoryProductsComponent',
  props: {
    partnerCategories: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    },
    setSelectCategory: {
      type: Function,
      default: () => null
    },
    getMenu: {
      type: Function,
      default: () => null
    },
    activeProduct: {
      type: Function,
      default: () => null
    },
    menuLoading: {
      type: Boolean,
      default: false
    },
    selectCategory: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Object,
      default: () => null
    },
    addMenu: {
      type: Function,
      default: () => null
    },
    filterProduct: {
      type: Function,
      default: () => null
    },
    addMenuCatgory: {
      type: Function,
      default: () => null
    },
    outletData: {
      type: Object,
      default: () => {}
    },
    getPartnerMenuCategories: {
      type: Function,
      default: () => []
    }
  },
  mounted () {
    this.$refs.searchInput.focus()
    if (this.$route.query.outletId) {
      this.currentOutlet = this.outletData
    }
  },
  data () {
    return {
      zoruuSelect: false,
      dialogCustomClass: 'custom-dialog-class',
      currentOutlet: null,
      searchText: '',
      copyProductDialog: false,
      copyLoading: false,
      deleteLoading: false,
      selectSuppliers: {
        suppliers: []
      },
      selectedProducts: [],
      rules: {
        suppliers: [
          {
            type: 'array',
            required: true,
            message: 'Та хуулах салбараа сонгоно уу?',
            trigger: 'change'
          }
        ]
      }
    }
  },
  watch: {
    outletData (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.selectedProducts = []
      }
    }
  },
  methods: {
    setOutlet (outlet) {
      this.selectedProducts = []
      this.getPartnerMenuCategories(outlet._id)
    },

    copyProduct (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const body = {}
          const tempData = []
          body.outletIds = this.selectSuppliers.suppliers
          this.selectedProducts.forEach(el => {
            tempData.push(el.pkey)
          })
          body.pkeys = tempData
          this.copyLoading = true
          mAxios
            .post('/product/copyProduct', body)
            .then(response => {
              if (response.data.status === 'success') {
                this.$message.success('Амжилттай хуулагдлаа')
                this.copyLoading = false
                this.copyProductDialog = false
                this.selectSuppliers.suppliers = []
                this.selectedProducts = []
              } else {
                this.$message.error(response.data.message)
                this.copyLoading = false
              }
            })
            .catch(e => {
              this.$message({
                message: e.response.data.message,
                type: 'error'
              })
              this.copyLoading = false
              this.copyProductDialog = false
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    deleteProducts () {
      const body = {}
      const tempData = []
      body.outlet_id = this.currentOutlet?._id ? this.currentOutlet?._id : this.outletData?._id
      this.selectedProducts.forEach(el => {
        tempData.push(el.pkey)
      })
      body.pkeys = tempData
      this.deleteLoading = true
      mAxios
        .delete('/product/multiDelete', { data: body })
        .then(response => {
          if (response.data.status === 'success') {
            this.$message.success('Амжилттай устгагдлаа')
            this.deleteLoading = false
            this.copyProductDialog = false
            this.selectedProducts = []
            this.getPartnerMenuCategories(this.currentOutlet?._id ? this.currentOutlet?._id : this.outletData?._id)
          } else {
            this.$message.error(response.data.message)
            this.deleteLoading = false
          }
        })
        .catch(e => {
          this.$message({
            message: e.response.data.message,
            type: 'error'
          })
          this.deleteLoading = false
          this.copyProductDialog = false
        })
    },

    closeCopyProductDialog () {
      this.copyProductDialog = false
    },

    setProduct (product) {
      if (product.is_checked) {
        this.selectedProducts.push(product)
      } else {
        this.selectedProducts = this.selectedProducts.filter(
          el => el.pkey !== product.pkey
        )
      }
      if (this.selectedProducts.length === 0) {
        this.copyProductDialog = false
      }
    }
  }
}
</script>

<style>
.custom-dialog-class {
  @media (max-width: 768px) {
    width: 100% !important;
  }
}
.custom-checkbox .el-checkbox__inner {
  background-color: #D9D9D9;
  border-color: #D9D9D9
}
.searchInputClass .el-input__inner {
  border-radius: 0px;
  border-top: 0px;
  border-left: 0px;
  outline: none;
}
.searchInputClass .el-input__inner {
  border-color: #E6E6E6
}
.element-select-category .el-input__inner {
  font-size: 12px;
  color: #231F20;
  border: none !important;
  border-radius: 12px !important;
  background-color: #E6E6E6;
}
*:focus {
  outline: none;
}
.el__input {
  border-radius: 50px
}
.Acitve {
  box-shadow: inset -11px 0 0px -7px #ffb500;
}
.productActiveClass {
  box-shadow: inset -11px 0 0px -7px black;
  background-color: #E6E6E6;
}
.diffHistory {
  /* background-color: #E4EBE4;  */
  color: rgb(135, 206, 135);
}
.fade-in {
  opacity: 0;
  animation: fade-in 0.5s ease-in forwards;
}

@keyframes fade-in {
  100% {
    opacity: 1;
  }
}
</style>
